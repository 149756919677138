<template>
    <div class="exam-in-wizard">
        <exam show-countdown />
    </div>
</template>

<script>
import Exam from '@/components/wizard/WizardExam/Exam';

export default {
    name: 'WizardExam',
    metaInfo: {
        title: 'Wizard Exam'
    },
    components: {
        Exam
    },
    data() {
        return {
            loading: false
        }
    }
}
</script>

<style lang="scss">
    .exam-in-wizard{
        margin-top: 40px;
        .exam_details{
            color: #000 !important;
        }
        .wizard__title {
            padding: 0 !important;
            margin-top: 0px !important;
        }
    }
</style>
